import { render, staticRenderFns } from "./ShopCart.vue?vue&type=template&id=9ffe987a&scoped=true&"
import script from "./ShopCart.vue?vue&type=script&lang=js&"
export * from "./ShopCart.vue?vue&type=script&lang=js&"
import style0 from "./ShopCart.vue?vue&type=style&index=0&id=9ffe987a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ffe987a",
  null
  
)

export default component.exports