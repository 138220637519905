<template>
  <div class="shop-cart">
    <h3>
      我的购物车&emsp;
      <span> 共有课程{{ cartList.length }}个,已选{{ checkList.length }}个</span>
      <button v-show="checkList.length > 1" @click="orederDel">删除选中</button>
    </h3>
    <div class="chk-box">
      <el-checkbox-group v-model="checkList" @change="chkChange">
        <div v-for="cart in cartList" :key="cart.id">
          <el-checkbox class="chk-item" :label="cart">
            <div class="chk-wrapper" @click.prevent>
              <div class="img-box">
                <img :src="cart.cover_img" alt="" />
              </div>
              <div class="chk-title">
                <span>{{ cart.title }}</span>
              </div>
              <div class="chk-price">
                <span>￥{{ cart.price }}</span>
              </div>
              <div class="chk-del-icon">
                <i class="el-icon-delete" @click="orederDel(cart)"></i>
              </div>
            </div>
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <div class="check-all" v-show="cartList.length !== 0">
        <el-checkbox
          :indeterminate="indeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <span class="total-price">￥{{ totalPrice }}</span>
        <button class="settlemnet" @click="settlement">结算</button>
      </div>
    </div>
    <div v-show="empty" style="margin-top: 10%">
      <el-empty
        description="购物车为空"
        :image="emptyCart"
        :image-size="500"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import emptyCart from "../assets/images/nocar.png";
export default {
  data() {
    return {
      checkList: [],
      cartList: [],
      checkAll: false,
      indeterminate: false,
      empty: false,
      emptyCart,
      totalPrice: 0,
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.getCartList();
  },
  methods: {
    getCartList() {
      this.axios.get("/course/pays/shop_cart").then((res) => {
        this.cartList = res.data.list;
        if (this.cartList.length === 0) {
          this.empty = true;
          this.checkAll = false;
          this.checkList = [];
        }
      });
    },
    chkChange(val) {
      console.log(val);
      this.totalPrice = this.countPrice(val);
      this.checkAll = this.cartList.length === val.length ? true : false;
      this.indeterminate = val.length > 0 && val.length < this.cartList.length;
    },
    handleCheckAllChange(val) {
      let arr = [];

      arr = this.cartList.map((cart) => {
        return cart;
      });
      this.checkList = val ? arr : [];
      this.indeterminate = false;
      this.countPrice(this.cartList);
      this.totalPrice = val ? this.countPrice(this.cartList) : 0;
    },
    orederDel(cart) {
      // 订单删除;
      this.$confirm("确定从购物车中移除吗？", "移除课程", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let fd = new FormData();
          if (cart.s_id) {
            fd.append("id", cart.s_id);
          } else {
            let ids = [];
            this.checkList.forEach((ele) => {
              ids.push(ele);
            });
            let str = ids.join(",");
            fd.append("id", ids);
          }
          this.axios.post("/course/pays/del_shop_cart", fd).then((res) => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              this.getCartList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
      return;
    },
    countPrice(items) {
      let init = 0;
      this.cartList.forEach((cart) => {
        items.forEach((item) => {
          let i = typeof item === "number" ? item : item.s_id;
          if (cart.s_id == i) {
            init += Number(cart.price);
          }
        });
      });
      return init.toFixed(2);
    },
    settlement() {
      if (this.checkList.length === 0) {
        this.$message.error("请选择商品进行支付");
        return;
      }
      let arr = [];
      arr = this.checkList.map((item) => {
        return item.id;
      });
      this.$router.push(`/order?oids=${arr}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.shop-cart {
  width: 1200px;
  min-height: 80vh;
  background-color: #fafafa;
  margin: 50px auto 50px;
  padding: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  h3 {
    font-size: 26px;
    position: relative;
    span {
      font-size: 20px;
      font-weight: normal;
    }

    button {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      font-size: 14px;
      float: right;
      width: 80px;
      height: 100%;
      border-radius: 50px;
      border: 0;
      color: #fff;
      background-color: rgba(red, 0.8);
      cursor: pointer;
    }
  }
  .chk-box {
    width: 90%;
    margin: 0 auto;
    .chk-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 150px;
      background-color: #fff;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
      padding: 20px;
      box-sizing: border-box;
      .chk-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: default;
        color: #333;
        overflow: hidden;

        .img-box {
          width: 300px;
          height: 120px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          img {
            max-width: 80%;
            max-height: 100%;
          }
        }
        .chk-title {
          width: 350px;
          font-size: 18px;
        }
        .chk-price {
          width: 200px;
          font-size: 20px;
          color: red;
          text-align: center;
        }
        .chk-del-icon {
          width: 110px;
          font-size: 18px;
          text-align: right;
          i {
            cursor: pointer;
          }
        }
      }
    }
    .check-all {
      // height: 100px;
      margin-top: 50px;
      position: relative;
      .total-price {
        color: red;
        font-size: 26px;
        position: absolute;
        right: 12%;
        top: 50%;
        transform: translateY(-50%);
      }
      .settlemnet {
        background-color: #409eff;
        color: #fff;
        border-radius: 50px;
        border: 0;
        padding: 10px 20px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}
</style>
